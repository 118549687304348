<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visable"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
    </v-alert>


    <view-intro class="text-start" heading="Alle vakken"/>

    <div class="d-flex justify-content-center" style="margin-top: 30vh;" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else style="gap: 20px; flex-wrap: wrap;" class="d-md justify-content-sm-start justify-content-center d-flex">
      <div @click="goToSection(section)" class="groupBlock shadow p-3 mb-5 bg-body rounded-4"
           v-for="(section, index) in sections" :key="index"
      >
        <p class="h5 mb-0">{{ section.section }}</p>
        <p class="mb-0 ml-3">{{ section.count }}</p>
      </div>
    </div>
  </v-container>
</template>

<script>

  export default {
    name: 'ProductsDocument',
    data () {

      this.$auth.getAllSections().then(sections => {
        this.sections = sections.filter(section => section.section !== '')
      }).catch(err => {
        this.alert.visable = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      }).finally(() => {
        this.loading = false
      })

      return {
        sections: [],
        loading: true,
        alert: {
          visible: false,
          description: '',
        },
      }
    },
    methods: {
      goToSection (section) {
        let sectionName = section.section
        sectionName = sectionName.replace('/', '%2F').replace('#', '%23\t')
        this.$router.push(`/section/${encodeURI(sectionName)}`)
      },
    },
  }
</script>

<style scoped>

.groupBlock {
  min-width: 150px;
  height: 150px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center
}
</style>
